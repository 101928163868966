import Vue from 'vue';
import VueRouter from 'vue-router';
import authService from '@/service/auth.service';
import autoRefreshService from '@/service/auto-refresh.service';
import esApiV3 from '@/service/esapiV3.service';
import { getSignedUrl } from '@/plugins/s3';
import { useContactListStore } from '@/stores/contactListStore';
import { useManufacturerStore } from '@/stores/manufacturerStore';
import { useAssetTypesStore } from '@/stores/assetTypesStore';
import { useServiceCategoryStore } from '@/stores/serviceCategoryStore';
import { useAreaStore } from '@/stores/areaStore';
import { useVendorStore } from '@/stores/vendorStore';
import { useTaskDistributionStore } from '@/stores/taskDistributionStore';
import { useUserPermissionsStore } from '@/stores/userPermissionsStore';
import { usePmStore } from '@/stores/pmStore';
import { useRequestDetailStore } from '@/stores/requestDetailStore';
import { useActivityStore } from '@/stores/activityStore';
import { useTaskStore } from '@/stores/taskStore';
import { usePmQuoteStore } from '@/stores/pmQuoteStore';
import { useAuthStore } from '@/stores/authStore';
import { useGlobalStore } from '@/stores/globalStore';

const TaskboardLayout = () =>
  import('@/components/taskboard/taskboard-layout.vue');
const TaskList = () => import('@/components/taskboard/tabs/task-list.vue');
const TaskboardAssignedTasks = () =>
  import('@/components/taskboard/tabs/assigned-tasks.vue');
const TaskboardAllTasks = () =>
  import('@/components/taskboard/tabs/all-tasks.vue');
const TaskboardAssignedRequests = () =>
  import('@/components/taskboard/tabs/assigned-requests.vue');
const TaskboardAllRequests = () =>
  import('@/components/taskboard/tabs/all-requests.vue');
const TaskDistributionLayout = () =>
  import('@/components/task-distribution/task-distribution-layout.vue');
const UserPermissionsLayout = () =>
  import('@/components/user-permissions/user-permissions-layout.vue');
const CoverageAreaLayout = () =>
  import('@/components/coverage-area/coverage-area-layout.vue');
const AssetTypesLayout = () =>
  import('@/components/asset-types/asset-types-layout.vue');
const WarrantyReviewLayout = () =>
  import('@/components/warranty/warranty-review-layout.vue');
const ServiceRequestIntakeLayout = () =>
  import(
    '@/components/service-request-intake/service-request-intake-layout.vue'
  );
const NewPMQuoteLayout = () =>
  import('@/components/pm/new-quote/pm-new-quote-layout.vue');
const ServiceRequestDetail = () =>
  import(
    '@/components/service-request-detail/service-request-detail-layout.vue'
  );
const ServiceRequestActivityTab = () =>
  import(
    '@/components/service-request-detail/tab-content/service-request-activity-tab.vue'
  );
const ServiceRequestLocationTab = () =>
  import(
    '@/components/service-request-detail/tab-content/location-tab/service-request-location-tab.vue'
  );
const ServiceRequestAssetTab = () =>
  import(
    '@/components/service-request-detail/tab-content/asset-tab/service-request-asset-tab.vue'
  );
const ServiceRequestDispatchTab = () =>
  import(
    '@/components/service-request-detail/tab-content/service-request-dispatch-tab.vue'
  );
const ServiceRequestQuoteTab = () =>
  import(
    '@/components/service-request-detail/tab-content/service-request-quote-tab.vue'
  );
const ServiceRequestNotesTab = () =>
  import(
    '@/components/service-request-detail/tab-content/service-request-notes-tab.vue'
  );
const ServiceRequestWorkOrderTab = () =>
  import(
    '@/components/service-request-detail/tab-content/service-request-work-order-tab.vue'
  );
const ServiceRequestCommunicationsTab = () =>
  import(
    '@/components/service-request-detail/tab-content/service-request-communications-tab.vue'
  );
const ServiceRequestSavingsLogTab = () =>
  import(
    '@/components/service-request-detail/tab-content/service-request-savings-log-tab.vue'
  );
const ServiceRequestAttachmentsTab = () =>
  import(
    '@/components/service-request-detail/tab-content/service-request-attachments-tab.vue'
  );
const ServiceRequestContractTab = () =>
  import(
    '@/components/service-request-detail/tab-content/service-request-contract-tab.vue'
  );
const VendorDetailLayout = () =>
  import('@/components/vendor/vendor-detail/vendor-detail-layout.vue');
const InvoicePage = () => import('@/components/invoice/invoice-page.vue');
const InvoiceGatherLayout = () =>
  import('@/components/invoice/tabs/invoice-gather-layout.vue');
const InvoiceMatchLayout = () =>
  import('@/components/invoice/tabs/invoice-match-layout.vue');
const InvoiceVeryfiLayout = () =>
  import('@/components/invoice/tabs/invoice-veryfi-layout.vue');
const VendorList = () => import('@/components/vendor/vendor-list.vue');
const PmLocationDetails = () =>
  import('@/components/pm/location/pm-location-details.vue');
const EditContractLayout = () =>
  import('@/components/pm/contract/edit-contract-layout.vue');
const PmHome = () => import('@/components/pm/pm-home.vue');
const PageNotFound = () => import('@/components/404/404.vue');
const AssetDetailLayout = () =>
  import('@/components/asset/asset-detail-layout.vue');
const CustomerDetailLayout = () =>
  import('@/components/customer/customer-detail-layout.vue');
const CustomerLayout = () =>
  import('@/components/customer/customer-layout.vue');
const CorporateListLayout = () =>
  import('@/components/customer/corporate-list-layout.vue');
const CustomerDetail = () =>
  import('@/components/customer/customer-detail.vue');
const LocationsListLayout = () =>
  import('@/components/location/locations-list-layout.vue');
const ContactsListLayout = () =>
  import('@/components/contact/contacts-list-layout.vue');
const AssetListLayout = () =>
  import('@/components/asset/asset-list-layout.vue');
const RequestsListLayout = () =>
  import('@/components/requests/requests-list-layout.vue');
const LocationDetailLayout = () =>
  import('@/components/location/location-detail-layout.vue');
const FlexIframeContent = () =>
  import('@/components/flex-iframe/iframe-content.vue');
const AssetQRCodeUrlGeneratorLayout = () =>
  import('@/components/qr-code-url-generator/qr-code-url-generator-layout.vue');
const LoginLayout = () => import('@/views/login-layout.vue');
const PortalLayout = () => import('@/views/portal-layout.vue');
const MaintenanceLayout = () => import('@/views/maintenance.vue');
const FlexQueuesLayout = () =>
  import('@/components/flex-queues/flex-queues-layout.vue');
const FlexRequestQueuesLayout = () =>
  import('@/components/flex-queues/flex-request-queues-layout.vue');
const FlexQueueLayout = () =>
  import('@/components/flex-queues/tabs/flex-queue-layout.vue');

Vue.use(VueRouter);

/**
 * @type {import('vue-router').RouteConfig[]}
 */
const routes = [
  {
    path: '/login',
    component: LoginLayout,
    props: (route) => ({ nextRoute: route.query.nextRoute }),
    name: 'Login',
    beforeEnter: (to, from, next) => {
      const globalStore = useGlobalStore();
      globalStore.resetState();
      authService.clearAuthToken();
      next();
    },
  },
  {
    path: '*',
    name: 'PageNotFound',
    component: PageNotFound,
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    component: MaintenanceLayout,
  },
  {
    path: '/',
    component: PortalLayout,
    redirect: '/taskboard',
    children: [
      {
        path: 'taskboard',
        name: 'Taskboard',
        component: TaskboardLayout,
        children: [
          {
            path: 'task-list',
            name: 'TaskList',
            component: TaskList,
          },
          {
            path: 'assigned-tasks',
            name: 'AssignedTasks',
            component: TaskboardAssignedTasks,
          },
          {
            path: 'all-tasks',
            name: 'AllTasks',
            component: TaskboardAllTasks,
          },
          {
            path: 'assigned-requests',
            name: 'AssignedRequests',
            component: TaskboardAssignedRequests,
          },
          {
            path: 'all-requests',
            name: 'AllRequests',
            component: TaskboardAllRequests,
          },
        ],
      },
      {
        path: 'task-distribution',
        name: 'TaskDistribution',
        component: TaskDistributionLayout,
        beforeEnter: async (to, from, next) => {
          const taskDistributionStore = useTaskDistributionStore();
          await Promise.all([
            taskDistributionStore.getAssigneesWithTasks(),
            taskDistributionStore.getAssignees(),
            taskDistributionStore.getTaskTypes(),
            taskDistributionStore.getCustomers(),
          ]);
          next();
        },
      },
      {
        path: 'user-permissions',
        name: 'UserPermissions',
        component: UserPermissionsLayout,
        meta: { permission: 'isAdmin' },
      },
      {
        path: 'coverage-areas',
        name: 'CoverageAreas',
        component: CoverageAreaLayout,
        beforeEnter: async (to, from, next) => {
          const areaStore = useAreaStore();
          await areaStore.getAllAreas();
          next();
        },
        meta: { permission: 'isAdmin' },
      },
      {
        path: 'asset-types',
        name: 'AssetTypes',
        component: AssetTypesLayout,
        beforeEnter: async (to, from, next) => {
          const serviceCategoryStore = useServiceCategoryStore();
          await serviceCategoryStore.getServiceCategories();
          next();
        },
        meta: { permission: 'isAdmin' },
      },
      {
        path: 'warranty-review',
        name: 'WarrantyReview',
        component: WarrantyReviewLayout,
        beforeEnter: async (to, from, next) => {
          const manufacturerStore = useManufacturerStore();
          const assetTypesStore = useAssetTypesStore();
          await Promise.all([
            manufacturerStore.getManufacturersForWarrantyReview(),
            assetTypesStore.getAllAssetTypes(),
          ]);
          next();
        },
        meta: { permission: 'isAdmin' },
      },
      {
        path: 'asset-qr-code-url-generator',
        name: 'AssetQRCodeUrlGenerator',
        component: AssetQRCodeUrlGeneratorLayout,
        meta: { permission: 'isAdmin' },
      },
      {
        path: 'flex-queues',
        name: 'FlexQueues',
        component: FlexQueuesLayout,
        meta: { permission: 'isAdmin' },
        redirect: 'flex-queues/emergency',
        children: [
          {
            path: 'emergency',
            name: 'FlexEmergencyQueue',
            component: FlexQueueLayout,
            props: {
              queueType: 'Emergency Queue',
              bucketNames: ['emergency'],
            },
          },
          {
            path: 'non-emergency',
            name: 'FlexNonEmergencyQueue',
            component: FlexQueueLayout,
            props: {
              queueType: 'Non-Emergency Queue',
              bucketNames: ['task-actions', 'non-emergency'],
            },
          },
          {
            path: 'actions',
            name: 'FlexActionsQueue',
            component: FlexQueueLayout,
            props: {
              queueType: 'Actions',
              bucketNames: ['adhoc-actions', 'stuck-tickets', 'stuck-tasks'],
            },
          },
          {
            path: 'after-hours',
            name: 'FlexAfterHoursQueue',
            component: FlexQueueLayout,
            props: {
              queueType: 'After Hours',
              bucketNames: ['after-hours-adhoc-actions', 'after-hours'],
            },
          },
        ],
      },
      {
        path: 'flex-request-queues',
        name: 'FlexRequestQueues',
        component: FlexRequestQueuesLayout,
        meta: { permission: 'isAdmin' },
        redirect: 'flex-request-queues/emergency',
        children: [
          {
            path: 'emergency',
            name: 'FlexEmergencyRequestQueue',
            component: FlexQueueLayout,
            props: {
              queueType: 'Emergency Priority Requests Queue',
              bucketNames: ['emergency-priority-tickets'],
              isRequestQueue: true,
            },
          },
          {
            path: 'high',
            name: 'FlexHighRequestQueue',
            component: FlexQueueLayout,
            props: {
              queueType: 'High Priority Requests Queue',
              bucketNames: ['high-priority-tickets'],
              isRequestQueue: true,
            },
          },
          {
            path: 'medium',
            name: 'FlexMediumRequestQueue',
            component: FlexQueueLayout,
            props: {
              queueType: 'Medium Priority Requests Queue',
              bucketNames: ['medium-priority-tickets'],
              isRequestQueue: true,
            },
          },
          {
            path: 'low',
            name: 'FlexLowRequestQueue',
            component: FlexQueueLayout,
            props: {
              queueType: 'Low Priority Requests Queue',
              bucketNames: ['low-priority-tickets'],
              isRequestQueue: true,
            },
          },
        ],
      },
      {
        path: 'new-request',
        name: 'ServiceRequestIntake',
        component: ServiceRequestIntakeLayout,
      },
      {
        path: 'new-pm-quote',
        name: 'NewPMQuote',
        component: NewPMQuoteLayout,
        props: (route) => ({ duplicate: route.query.duplicate }),
        beforeEnter: async (to, from, next) => {
          const serviceCategoryStore = useServiceCategoryStore();
          const pmQuoteStore = usePmQuoteStore();
          await serviceCategoryStore.getServiceCategories();
          if (to.query.duplicate) {
            await pmQuoteStore.duplicateQuote(to.query.duplicate);
          }
          next();
        },
      },
      {
        path: 'request/:id',
        component: ServiceRequestDetail,
        beforeEnter: async (to, from, next) => {
          const requestDetailStore = useRequestDetailStore();
          const activityStore = useActivityStore();
          const taskStore = useTaskStore();
          const request = await requestDetailStore.getRequestById(to.params.id);
          if (!['Incident', 'Preventative'].includes(request.requestType)) {
            next({ name: 'Taskboard' });
            return;
          }
          await Promise.all([
            activityStore.getActivitiesForRequest({
              requestId: to.params.id,
            }),
            taskStore.getTasksForRequest(to.params.id),
          ]);
          next();
        },
        children: [
          {
            path: '',
            component: ServiceRequestActivityTab,
          },
          {
            path: 'activity',
            name: 'RequestActivity',
            component: ServiceRequestActivityTab,
          },
          {
            path: 'location',
            name: 'RequestLocation',
            component: ServiceRequestLocationTab,
          },
          {
            path: 'asset',
            name: 'RequestAsset',
            component: ServiceRequestAssetTab,
          },
          {
            path: 'dispatch',
            name: 'RequestDispatch',
            component: ServiceRequestDispatchTab,
          },
          {
            path: 'communication',
            name: 'RequestCommunication',
            component: ServiceRequestCommunicationsTab,
          },
          {
            path: 'quote',
            name: 'RequestQuote',
            component: ServiceRequestQuoteTab,
          },
          {
            path: 'notes',
            name: 'RequestNotes',
            component: ServiceRequestNotesTab,
          },
          {
            path: 'work-order',
            name: 'RequestWorkOrder',
            component: ServiceRequestWorkOrderTab,
          },
          {
            path: 'savings',
            name: 'RequestSavings',
            component: ServiceRequestSavingsLogTab,
          },
          {
            path: 'attachments',
            name: 'RequestAttachments',
            component: ServiceRequestAttachmentsTab,
          },
          {
            path: 'contract',
            name: 'RequestContract',
            component: ServiceRequestContractTab,
          },
        ],
      },
      {
        path: 'vendors',
        name: 'Vendors',
        component: VendorList,
      },
      {
        path: 'vendor-detail/:id',
        name: 'VendorDetail',
        component: VendorDetailLayout,
        beforeEnter: async (to, from, next) => {
          const serviceCategoryStore = useServiceCategoryStore();
          const areaStore = useAreaStore();
          const vendorStore = useVendorStore();
          await Promise.all([
            vendorStore.setVendorById({
              vendorId: to.params.id,
              query: {
                relations: [
                  'vendorAcceptedCommunicationMethods',
                  'vendorOverallRating',
                  'vendorServiceCategoryRating',
                  'vendorAutomationOptions',
                  'vendorBulkFollowUpTimes',
                  'vendorDefinedEta',
                ],
              },
            }),
            serviceCategoryStore.getServiceCategories(),
            areaStore.getAllAreas(),
          ]);
          next();
        },
      },
      {
        path: 'invoices',
        name: 'InvoicePage',
        component: InvoicePage,
        redirect: 'invoices/transcribe',
        children: [
          {
            path: 'gather',
            name: 'InvoiceGather',
            component: InvoiceGatherLayout,
          },
          {
            path: 'transcribe',
            name: 'InvoiceTranscribe',
            component: InvoiceVeryfiLayout,
          },
          {
            path: 'transcribe/:id',
            name: 'InvoiceTranscribeById',
            component: InvoiceVeryfiLayout,
          },
          {
            path: 'match',
            name: 'InvoiceMatch',
            component: InvoiceMatchLayout,
          },
        ],
      },
      {
        path: 'pm',
        name: 'PmHome',
        component: PmHome,
        beforeEnter: async (to, from, next) => {
          const pmStore = usePmStore();
          await pmStore.getPmCustomers();
          next();
        },
      },
      {
        path: 'pm/location/:locationId/',
        name: 'PmLocationDetails',
        component: PmLocationDetails,
      },
      {
        path: 'pm/location/:locationId/contract/:contractId',
        name: 'PMContractDetails',
        component: EditContractLayout,
      },
      {
        path: 'customers',
        component: CustomerLayout,
        children: [
          {
            path: '',
            name: 'CorporateCustomers',
            component: CorporateListLayout,
          },
          {
            path: 'locations',
            name: 'Locations',
            component: LocationsListLayout,
          },
        ],
      },
      {
        path: 'customers/:customerId',
        component: CustomerDetailLayout,
        children: [
          {
            path: '',
            name: 'CustomerDetail',
            component: CustomerDetail,
          },
          {
            path: 'locations',
            name: 'CustomerLocations',
            component: LocationsListLayout,
          },
          {
            path: 'locations/:locationId',
            name: 'CustomerLocationDetail',
            component: LocationDetailLayout,
          },
          {
            path: 'contacts',
            name: 'CustomerContacts',
            component: ContactsListLayout,
            beforeEnter: async (to, from, next) => {
              const contactListStore = useContactListStore();
              await contactListStore.resetList();
              next();
            },
          },
          {
            path: 'assets',
            name: 'CustomerAssets',
            component: AssetListLayout,
          },
          {
            path: 'assets/:assetId',
            name: 'CustomerAssetDetail',
            component: AssetDetailLayout,
          },
          {
            path: 'requests',
            name: 'CustomerRequests',
            component: RequestsListLayout,
          },
        ],
      },
    ],
  },
  {
    path: '/s3',
    name: 'S3',
    component: {
      template: '<div>S3</div>',
    },
    beforeEnter: async (to, from, next) => {
      const url = await getSignedUrl(to.query.url, esApiV3);
      window.location.href = url;
      next();
    },
  },
  {
    path: '/flex',
    component: FlexIframeContent,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: import.meta.env.BASE_URL,
  routes,
  scrollBehavior(_to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (_to.params.savePosition) {
      return {};
    }
    return { x: 0, y: 0 };
  },
});

async function populateUserAndNav() {
  const authStore = useAuthStore();
  if (!authStore.hasUserInfo) {
    await authStore.getUserInfo();
  }
}

function insufficientPermissionsForRoute(to) {
  const authStore = useAuthStore();
  const routeRequiresAdmin = to.matched.some(
    (record) => record.meta.permission === 'isAdmin',
  );
  return routeRequiresAdmin && !authStore.hasAdminPermission;
}

router.beforeEach(async (to, from, next) => {
  await autoRefreshService.handleNewVersionAvailable();

  if (to.name === 'Login') {
    next();
    return;
  }
  if (!authService.hasAuthToken()) {
    next({
      name: 'Login',
      query: { nextRoute: new URL(window.location.href).toString() },
    });
    return;
  }
  const userPermissionsStore = useUserPermissionsStore();

  if (!userPermissionsStore.hasUsersByPermission) {
    userPermissionsStore.getUsersByPermission('isRequestAssignee');
    userPermissionsStore.getUsersByPermission('isTaskAssignee');
  }
  await populateUserAndNav();
  if (insufficientPermissionsForRoute(to)) {
    next('/');
    return;
  }

  next();
});

export default router;
