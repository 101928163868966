import { defineStore } from 'pinia';
import requestsService from '@/service/requests.service';
import requestAttachmentsService from '@/service/request-attachments.service';
import pmQuotesService from '@/service/pm-quotes.service';
import assetService from '@/service/asset.service';
import customerService from '@/service/customer.service';
import { getAllPaginatedItems } from '@/utils';
import RequestStatuses from '@/constants/requestStatuses';
import { serviceCompleteExemptTaskTypes } from '@/constants/activities';
import dayjs from 'dayjs';

export const useRequestDetailStore = defineStore('requestDetail', {
  state: () => ({
    request: {},
    isLoading: true,
    warrantyBadgeOptions: {
      UNDER_WARRANTY: {
        name: 'Under Warranty',
        color: 'success',
        icon: 'mdi-checkbox-marked-circle',
      },
      NOT_UNDER_WARRANTY: {
        name: 'Not Under Warranty',
        color: 'error',
        icon: 'mdi-close-circle',
      },
      UNSURE_IF_UNDER_WARRANTY: {
        name: 'Unsure if Under Warranty',
        color: 'warning',
        icon: 'mdi-alert-circle',
      },
      SKIPPED_WARRANTY: {
        name: 'Warranty Check Skipped',
        color: 'info',
        icon: 'mdi-alert-circle',
      },
    },
    archiveInProgress: false,
    logActivityType: 'Follow up',
    taskBarActiveTab: null,
    overviewHeight: 0,
    contractDetails: null,
    contractDetailSchedule: null,
    quoteReasonablenessReport: null,
    autoCloseDate: null,
    requestAsset: null,
    emailsSent: 0,
    isLoadingVendorsWithPaymentTerms: false,
    vendorsWithPaymentTermIds: [],
  }),
  actions: {
    async getRequestById(id) {
      this.isLoading = true;
      const request = await requestsService.getRequestById(id, {
        relations: [
          'contacts',
          'symptoms',
          'problems',
          'solutions',
          'assignedToVendor',
          'requestedByUser',
          'deletedByUser',
          'location',
          'location.area',
          'location.bestHoursForRepair',
          'location.hoursOfOperation',
          'customer',
          'pm.requestAssets.chores.pmChoreType',
          'pm.requestAssets.asset',
          'pm.requestAssets.asset.assetType',
        ],
      });
      this.request = request;
      if (request.assetId) {
        const requestAsset = await assetService.getAssetDetailsById(
          request.assetId,
        );
        this.requestAsset = requestAsset;
      }
      this.isLoading = false;
      return request;
    },
    async patchRequest({ requestId, changes }) {
      return requestsService.updateRequest(requestId, changes);
    },
    async archiveRequest({ requestId, archivedReason }) {
      this.archiveInProgress = true;
      const payload = {
        newStatus: RequestStatuses.REQUEST_CLOSED,
        reason: 'Archived',
      };
      await this.changeStatus({ requestId, payload });
      await requestsService.deleteRequest(requestId, archivedReason);
      await this.getRequestById(requestId);
      window.scrollTo(0, 0);
      this.archiveInProgress = false;
    },
    async unarchiveRequest(requestId) {
      this.archiveInProgress = true;
      await requestsService.unarchiveRequest(requestId);
      const payload = {
        newStatus: RequestStatuses.REQUEST_OPEN,
        reason: 'Unarchived',
      };
      await this.changeStatus({ requestId, payload });
      await this.getRequestById(requestId);
      window.scrollTo(0, 0);
      this.archiveInProgress = false;
    },
    async changeStatus({ requestId, payload }) {
      await requestsService.updateRequestStatus(requestId, payload);
      this.request.requestStatus = payload.newStatus;
    },
    async changePriority({ requestId, payload }) {
      await requestsService.updateRequestPriority(requestId, payload);
      this.request.requestPriority = payload.newPriority;
    },
    async uploadFile({ file, requestId, attachmentType, taskId, userId }) {
      const response = await requestAttachmentsService.uploadAttachment(
        file,
        requestId,
        attachmentType,
        taskId,
        userId,
      );
      return response;
    },
    async deleteFile({ fileId, attachmentType }) {
      const response = await requestAttachmentsService.deleteAttachment(
        fileId,
        {
          attachmentType,
        },
      );
      return response.attachments;
    },
    setTaskBarActivityToLog(value) {
      this.logActivityType = value;
    },
    setTaskBarActiveTab(value) {
      this.taskBarActiveTab = value;
    },
    async patchTroubleshootingItems({ requestId, type, items }) {
      const changes = {
        [type]: items,
      };
      return requestsService.updateRequest(requestId, changes);
    },
    setOverviewHeight(height) {
      this.overviewHeight = height + 52;
    },
    async getContractByRequest(query) {
      const allQuotes = await getAllPaginatedItems(
        pmQuotesService.getPmQuotesByQuery,
        {
          ...query,
          disableEagerRelations: true,
          relations: [
            'pmSchedule.pmScheduleAssets.asset',
            'attachments',
            'serviceHourRanges',
            'notes',
          ],
        },
      );
      [this.contractDetails] = allQuotes;
      this.contractDetailSchedule = allQuotes[0].pmSchedule;
      return allQuotes?.[0];
    },
    async getQuoteReasonablenessData(requestId) {
      const data = await requestsService.getQuoteReasonablenessData(requestId);
      this.quoteReasonablenessReport = data;
    },
    async getDateForAutoClosure(id) {
      const autoCloseDate = await requestsService.getDateForAutoClosure(id);
      this.autoCloseDate = autoCloseDate;
    },
    async getVendorsWithPaymentTermsForRequestCustomer(customerId) {
      this.isLoadingVendorsWithPaymentTerms = true;
      const allVendors =
        await customerService.getVendorsWithPaymentTermsForCustomer(customerId);
      this.vendorsWithPaymentTermIds = allVendors;
      this.isLoadingVendorsWithPaymentTerms = false;
    },
    setRequestEtaDetails(etaDetails) {
      this.request.vendorEtaDescription = etaDetails.vendorEtaDescription;
      this.request.vendorEtaStartDate = etaDetails.vendorEtaStartDate;
      this.request.vendorEtaEndDate = etaDetails.vendorEtaEndDate;
      this.request.vendorEtaStartTime = etaDetails.vendorEtaStartTime;
      this.request.vendorEtaEndTime = etaDetails.vendorEtaEndTime;
    },
  },
  getters: {
    allRequestStatuses: () => Object.values(RequestStatuses),
    serviceCompleteExemptTaskTypes: () =>
      Object.values(serviceCompleteExemptTaskTypes),
    requestId: (state) => state.request?.id,
    ticketNumber: (state) => state.request?.ticketNumber,
    requestType: (state) => state.request?.requestType,
    isTypeIncident: (state) => state.request?.requestType === 'Incident',
    requestLocation: (state) => state.request?.location,
    requestCoverageArea: (state) => state.request?.location?.area?.displayName,
    requestCustomer: (state) => state.request?.customer,
    requestVendor: (state) => state.request?.assignedToVendor,
    requestAssetTypeId: (state) => state.requestAsset?.assetTypeId,
    requestServiceCategory: (state) =>
      state.requestAsset.assetType?.serviceCategoryId,
    requestContacts: (state) => state.request.contacts,
    requestAssetSymptoms: (state) => state.request.symptoms,
    requestAssetProblems: (state) => state.request.problems,
    requestAssetSolutions: (state) => state.request.solutions,
    emailSubjectLine: (state) => {
      let subject = state.request.ticketNumber;
      if (state.request.requestType === 'Preventative') {
        subject += ` - ${state.request.incidentDescription}`;
      } else {
        if (state.requestAsset) {
          subject += ` - ${state.requestAsset.description}`;
        }
        if (state.request.location) {
          subject += ` - ${state.request.location.name}`;
        }
      }
      return subject;
    },
    alternateEmailSubjectLine: (state) => {
      let subject = '';
      const ticket = state.request.ticketNumber;
      const location = state.request.location.name;
      const asset = state.requestAsset.description;
      if (state.request.requestType === 'Preventative') {
        subject = `${ticket} - ${state.request.incidentDescription}`;
      } else {
        subject = `${location} - ${asset} - ${ticket}`;
      }
      return subject;
    },
    taskBarItems: (state) => {
      const items = [
        { label: 'Add Task', content: 'task-bar-add-task' },
        { label: 'Log Activity', content: 'task-bar-log-activity' },
      ];
      if (state.request?.requestType === 'Incident') {
        items.push({ label: 'Communicate', content: 'task-bar-communicate' });
      }
      items.push({ label: 'Phone', content: 'task-bar-phone' });
      return items;
    },
    // PM Specific getters
    pmAssetsForRequest: (state) =>
      state.request?.pm?.requestAssets?.filter(
        (requestAsset) => requestAsset?.asset?.deletedAt === null,
      ),
    assetChoresForRequest: (state) =>
      state.request?.pm?.requestAssets
        ?.filter((requestAsset) => requestAsset?.asset?.deletedAt === null)
        ?.map((asset) => asset?.chores)
        ?.flat(),
    completedChoreCountForRequest() {
      return this.assetChoresForRequest?.filter((chore) => chore?.isComplete)
        ?.length;
    },
    totalChoreCountForRequest() {
      return this.assetChoresForRequest?.length;
    },
    serviceWindow: (state) => {
      const format = (dateString) =>
        dayjs(dateString).utc().format('MM/DD/YYYY');
      if (
        !state?.request?.pm?.serviceWindowEndsAt &&
        !state?.request?.pm?.serviceWindowStartsAt
      ) {
        return '';
      }
      if (!state.request?.pm?.serviceWindowEndsAt) {
        return format(state.request?.pm?.serviceWindowStartsAt);
      }
      return `${format(state.request?.pm?.serviceWindowStartsAt)} - ${format(
        state.request?.pm?.serviceWindowEndsAt,
      )}`;
    },
    canReschedulePmVisit() {
      // used to check if Proof of Work was completed. That logic was removed in BOH-4973
      return true;
    },
  },
});
